<template>
  <div class="home">
    <api-key-form v-if="apiKeyExist" />
    <div
      v-else
      class="home-grid"
    >
      <hero-with-btns class="full-width" />
      <div
        right
        class="version"
      >
        {{ version }}
      </div>

      <v-container
        fill-height
        justify-center
      >
        <input-search-company
          class=""
          @searchFor="filterBy"
        />
        <v-btn
          @click="forceReload()"
        >
          Reload
        </v-btn>
      </v-container>

      <card-company-wall
        style="overflow: auto"
        :filter-companies="filterCompany"
      />
    </div>
  </div>
</template>

<script>
import packagesData from '@/../package.json';
import HeroWithBtns from '../components/HeroWithBtns.vue';
import InputSearchCompany from '../components/InputSearchCompany.vue';
import CardCompanyWall from '../components/CardCompanyWall.vue';
import ApiKeyForm from '../components/ApiKeyForm.vue';

export default {
  name: 'Home',
  components: {
    HeroWithBtns,
    InputSearchCompany,
    CardCompanyWall,
    ApiKeyForm,
  },
  data() {
    return {
      filterCompany: '',
      apiKeyExist: false,
      version: 12,
      packages: packagesData,
    };
  },
  created() {
    // const API_KEY = localStorage.getItem('sonnette-api-key');
    // console.log('API_KEY: ', API_KEY);
    // this.apiKeyExist = API_KEY === null;
    this.getPackageVersion();
  },
  methods: {
    filterBy(filter) {
      this.filterCompany = filter;
    },

    getPackageVersion() {
      const version = this.packages.version || 'v0.0.0';
      this.version = version;
    },

    forceReload() {
      this.$root.forceReload();
    },

  },
};
</script>

<style scoped>
.home-grid {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 8fr 8fr 25fr;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.version {
  color: #cccccc;
  text-align: right;
}
</style>
