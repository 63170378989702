<template>
  <div
    class="input-search-company"
  >
    <v-text-field
      v-model="companyFilter"
      :label="$t('vous_souhaitez_contacter_une_société_hébergée')"
      outlined
      clearable
      @focus="showVirtualKeyboard = true"
    >
      <v-icon
        slot="prepend"
        color="grey"
      >
        mdi-account-search
      </v-icon>
    </v-text-field>
    <div
      v-if="showVirtualKeyboard"
      class="virtual-keyboard"
    >
      <div
        class="close-keyboard"
        @click="hideKeyboard"
      >
        <span class="close-icon">&times;</span>
      </div>
      <SimpleKeyboard
        :input="input"
        @onChange="onChange"
        @onKeyPress="onKeyPress"
      />
    </div>
  </div>
</template>

<script>
import SimpleKeyboard from './SimpleKeyboard.vue';

export default {
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      companyFilter: '',
      showVirtualKeyboard: false,
      input: '',
    };
  },
  watch: {
    companyFilter(nVal) {
      this.$emit('searchFor', nVal);
    },
  },
  methods: {
    clearFilter() {
      this.companyFilter = '';
    },
    onChange(input) {
      this.companyFilter = input;
      this.showVirtualKeyboard = true;
    },
    onKeyPress(button) {
      this.showVirtualKeyboard = true;
      console.log('button', button);
    },
    onInputChange(input) {
      this.showVirtualKeyboard = true;
      this.companyFilter = input.target.value;
    },
    hideKeyboard() {
      this.showVirtualKeyboard = false;
    },
  },
};
</script>

<style scoped>
.input-search-company {
  width: 80%;
  margin-bottom: -32px;
}

.virtual-keyboard {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 10;
  left: 0;
}

.close-keyboard {
  width: 100vw;
  display: flex;
  justify-content: flex-end;
}

.close-icon {
  background-color: white;
  font-size: 48px;
  padding: 0px 24px;
  border: 8px solid #ececec;
}

@media screen and (max-width: 600px) {
  .input-search-company{
    width: 100%;
  }
}
</style>
