<template>
  <v-app>
    <!-- <v-toolbar app dark class="indigo">
      <v-toolbar-title class="headline text-uppercase">
        <span>Village</span>
        <span class="font-weight-light"> SONNETTE</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <local-switcher/>
      <p>{{ $t('bonjour')}}</p>
    </v-toolbar> -->

    <v-main class="main-content">
      <router-view />
      <div style="display: none">
        <local-switcher />
        <p>{{ $t('bonjour') }}</p>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import LocalSwitcher from './components/LocalSwitcher.vue';

export default {
  name: 'App',

  components: {
    LocalSwitcher,
  },

  data: () => ({
    //
  }),

  methods: {
  },
};
</script>

<style scoped>
.main-content {
  /* margin-top: 3vh; */
  margin-right: 3vw;
  margin-left: 3vw;
}
</style>
