import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import { createProvider } from './vue-apollo';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: createProvider(),
  methods: {
    forceReload() {
      window.location.reload(true);
    },
  },
  render: (h) => h(App),
}).$mount('#app');
