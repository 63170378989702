<template>
  <div>
    <v-card
      class="card-size pa-12 ma-6"
      >
      <v-card-title>{{ title }}</v-card-title>
      <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      >
        <v-text-field
          v-model="apiKey"
          label="Clé d'API"
          autofocus
          required
        ></v-text-field>

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="recordApiKey"
        >
          Enregistrer la clé d'API
        </v-btn>

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="reloadView"
        >
          Lancer l'application
        </v-btn>

      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: 'Enregistrer votre clé d\' API.',
      apiKey: '',
      valid: false,
    };
  },
  watch: {
    apiKey(nVal) {
      this.valid = nVal.length > 0;
    },
  },
  methods: {
    async recordApiKey() {
      const apiOK = await localStorage.setItem('sonnette-api-key', this.apiKey);
      console.log('apiOK: ', apiOK);
    },
    reloadView() {
      this.$router.go();
    },
  },
};
</script>

<style scoped>
.super-class {
  background: #4b79b6;
}
</style>
