<template>
  <v-card
    class="card-size"
    @click="ringCompany"
  >
    <v-img
      padding="10"
      :aspect-ratio="1/1"
      :src="image"
    />

    <v-card-title>{{ title }}</v-card-title>

    <!-- <v-card-actions>
    </v-card-actions> -->
  </v-card>
</template>

<script>
import gql from 'graphql-tag';

export default {
  props: {
    title: {
      type: String,
      default: 'Company name',
    },
    image: {
      type: String,
      default: '../assets/companyImages/company1.jpg',
    },
    companyId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      msg: 'Hi there!',
    };
  },
  methods: {
    ringCompany() {
      this.sendMail();
      this.$router.push({
        name: 'Ring',
        params: { companyName: this.title, companyLogo: this.image },
        query: { rotate: this.$route.query.rotate },
      });
    },

    async sendMail() {
      // console.log('SEND MAIL TO: ', this.companyId, this.title);
      const mail = await this.$apollo.query({
        query: gql`
          query Notify($input: HomeCompanyUpdateInput) {
            NotifyCompany(input: $input) {
              companies {
                email
              }
            }
          }`,
        variables: {
          input: {
            companyId: this.companyId,
          },
        },
        fetchPolicy: 'network-only',
      });
      // console.log('MAIL SENT: ', mail);
      return mail;
    },
  },
};
</script>

<style scoped>
.card-size {
  width: auto;
}

@media screen and (max-width: 600px) {
  .card-size{
    width: 70vw;
  }
}
</style>
