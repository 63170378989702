<template>
  <div class="wall-grid">
    <div
      v-for="company in companiesSorted"
      :key="company.id"
    >
      <card-company
        :title="company.name"
        :image="company.logo"
        :company-id="company.companyId"
      />
    </div>
    <div class="grid-bottom-spacer" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import CardCompany from './CardCompany.vue';

export default {
  components: {
    CardCompany,
  },
  props: {
    filterCompanies: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      companies: [],
      filteredCompanies: [],
      refreshLater: 0,
      timer: '',
      timerDelayFast: 1 * 1000, // timer value when a data refresh failed
      timerDelaySlow: 10 * 1000, // timer value when data refresh run as expected
      refreshDataTimeout: 15 * 1000, // time out value for a data request
      isDataRefreshing: true,
      isDataRefreshError: true,
      requestFailedCount: 0,

    };
  },

  computed: {
    companiesSorted() {
      const companies = [...this.filteredCompanies];
      return companies.sort((a, b) => {
        if (a.name.toLowerCase() === b.name.toLowerCase()) {
          return 0;
        }
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
    },
  },

  watch: {
    filterCompanies(nVal) {
      this.applyCompanyFilter(nVal);
    },
  },

  created() {
    this.filteredCompanies = this.companies;
    this.requestFailedCount = 0;
    this.refreshCompanies();
  },

  beforeDestroy() {
    this.cancelTimer();
  },

  methods: {
    applyCompanyFilter(filterString) {
      if (['', null, undefined].includes(filterString)) {
        this.filteredCompanies = this.companies;
      } else {
        this.filteredCompanies = [];
        this.filteredCompanies = this.companies.filter((elm) => {
          const nameLower = elm.name.toLowerCase();
          return nameLower.indexOf(filterString.toLowerCase()) > -1;
        });
      }
    },

    async getThenDispatchCompanies() {
      this.getHomeCompanies().then((r) => {
        this.companies = r;
        this.applyCompanyFilter(this.filterCompanies);
        this.setTimer(this.timerDelaySlow);
      });
      return true;
    },

    // refreshCompanies() {
    // },

    async refreshCompanies() {
      this.cancelTimer();
      const timeout = this.timeoutPromise();
      const refresherFunction = this.getThenDispatchCompanies();
      try {
        await Promise.race([refresherFunction, timeout]);
      } catch (error) {
        this.setTimer(this.timerDelayFast);
        this.requestFailedCount += 1;
        if (this.requestFailedCount > 5) {
          this.$root.forceReload();
        }
        throw error;
      }
    },

    async getHomeCompanies() {
      // console.log('SEND MAIL');
      try {
        const res = await this.$apollo.query({
          query: gql`
            query home ($query: HomeQueryInput) {
              home (query: $query) {
                _id
                companies {
                  companyId
                  name
                  email
                  logo
                }
                contactURL
                homeImage
                isDeliverySpot
              }
            }`,
          fetchPolicy: 'network-only',
        });
        this.requestFailedCount = 0;
        // console.log('HOME RES: ', res.data.home.companies);
        return res.data.home.companies;
      } catch (error) {
        if (error.message !== undefined && error.message.search('Network') > -1) {
          this.requestFailedCount += 1;
          if (this.requestFailedCount > 5) {
            this.$root.forceReload();
          }
        }
        this.setTimer(this.timerDelayFast);
        throw error;
      }
    },

    async timeoutPromise() {
      return new Promise((function executor(reject) {
        setTimeout(() => reject('timeout triggered'), this.refreshDataTimeout);
      }));
    },

    setTimer(delay) {
      this.timer = setInterval(this.refreshCompanies, delay);
    },

    cancelTimer() {
      clearInterval(this.timer);
    },

  },
};
</script>

<style scoped>
.wall-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: max-content;
  gap: 3vw;
  padding: 3vw;
  background-color: inherited;
}

.grid-bottom-spacer {
  grid-column: 1/-1;
  height: 24px;
}

@media screen and (max-width: 600px) {
  .wall-grid {
    display: inline-flex;
  }
}

@media screen and (min-width: 1024px) {
  .wall-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
