<template>
  <div
    :class="keyboardClass"
  />
</template>

<script>
import Keyboard from 'simple-keyboard';
import LAYOUT_FR from 'simple-keyboard-layouts/build/layouts/french';
import 'simple-keyboard/build/css/index.css';

export default {
  name: 'SimpleKeyboard',
  props: {
    keyboardClass: {
      type: String,
      default: 'simple-keyboard',
    },
    input: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    keyboard: null,
  }),
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
    });

    this.keyboard.setOptions({
      layout: LAYOUT_FR.layout,
      mergeDisplay: true,
      display: {
        '{bksp}': 'Supp',
        '{enter}': 'Entrée',
        '{shift}': 'MAJ',
      },
    });
  },
  methods: {
    onChange(input) {
      this.$emit('onChange', input);
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === '{shift}' || button === '{lock}') this.handleShift();
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
};
</script>

<style>
  /**
   * Overwrite simple-keyboard class
   */
  .hg-theme-default .hg-button {
    height: 60px !important;
  }

  .hg-theme-default .hg-button > span {
    font-size: 26px;
  }
</style>
