import * as Realm from 'realm-web';
// import Auth from '@aws-amplify/auth';

// Village access
const APP_ID = 'village-app-fivkd';
const TOKEN = '7tANTJ9o84KhemXoPdXtmhCtvQa71LgnxnHPyGROc9kWC9njYJbzYJYlYlMPnNPX';
// const TOKEN = localStorage.getItem('sonnette-api-key');
// console.log('get apiKey here: ', TOKEN);

// Connect to your MongoDB Realm app
const app = new Realm.App(APP_ID);
// Get a valid Realm user access token to authenticate requests
/**
 * Get a valid token with the cognito token
 * @returns {String} - A fresh JWT to access mongo realm GraphQl API
 */
const getValidAccessToken = async () => {
  if (!app.currentUser && TOKEN) {
    // If no user is logged in, log in an anonymous user
    // const token = await Auth.currentSession()
    //   .then((session) => session.idToken.jwtToken);
    // console.log('the token is: ', TOKEN);
    await app.logIn(Realm.Credentials.apiKey(TOKEN));
  } else {
    // The logged in user's access token might be stale,
    // Refreshing custom data also refreshes the access token
    await app.currentUser.refreshCustomData();
  }
  // Get a valid access token for the current user
  return app.currentUser.accessToken;
};

export default getValidAccessToken;
