<template>
  <div>
    <div class="ring">
      <v-img
        :aspect-ratio="1 / 1"
        :width="300"
        class="img-circle"
        :src="companyLogo"
      />
      <v-progress-linear
        style="margin: 12vw 0 12vw 0"
        height="12px"
        rounded
        indeterminate
        color="green"
      />
      <p class="display-1">
        {{ companyName }} a été prévenu, quelqu’un arrive.
      </p>
    </div>

    <!-- Back home button -->
    <div class="back-button">
      <v-btn
        dark
        color="indigo"
        style="width:250px;"
        @click="backHomePage"
      >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        <span>{{ $t("retour_a_l_acceuil") }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ringing',
  data() {
    return {
      companyName: 'L\'entreprise',
      companyLogo: '../assets/companyImages/company1.jpg',
    };
  },
  mounted() {
    const delayInMilliseconds = 1000 * 30;

    setTimeout(() => {
      this.backHomePage();
    }, delayInMilliseconds);
  },
  created() {
    this.companyName = this.$route.params.companyName;
    // console.log('this.$route.params: ', this.$route.params);
    this.companyLogo = this.$route.params.companyLogo;
  },
  methods: {
    backHomePage() {
      new Promise((resolve) => {
        this.$router.push({ name: '/nothing', query: { rotate: this.$route.query.rotate } });
        resolve();
      }).then(() => {
        this.$router.push({ name: 'Home', query: { rotate: this.$route.query.rotate } });
      });
    },
  },
};
</script>

<style scoped>
.ring {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
  margin: auto;
  text-align: center;
}

.img-circle {
  border-radius: 50%;
  margin-top: 24vw;
}

.back-button {
  position: fixed;
  bottom: 20px;
}
</style>
