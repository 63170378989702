<template>
  <div
    class="hero-with-btns"
    :style="{ 'background-image': 'url(' + heroUrl + ')'}"
  >
    <div class="call-to-action">
      <div style="height: 6px" />
      <button-ring />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import ButtonRing from './ButtonRing.vue';

export default {
  components: {
    ButtonRing,
  },
  data() {
    return {
      heroUrl: 'Hi there!',
    };
  },
  created() {
    this.getHome().then((r) => {
      this.heroUrl = r;
      return 'OK';
    });
  },
  methods: {
    async getHome() {
      const res = await this.$apollo.query({
        query: gql`
          query home ($query: HomeQueryInput) {
            home (query: $query) {
              _id
              contactURL
              homeImage
              isDeliverySpot
            }
          }`,
        fetchPolicy: 'network-only',
      });
      // console.log('HOME RES: ', res.data.home.companies);
      return res.data.home.homeImage;
    },
  },
};
</script>

<style scoped>
.hero-with-btns {
  min-height: 25vh;
  background: url(../assets/companyImages/company1.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.call-to-action {
  padding: 12px;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
