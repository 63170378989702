---
INFO: LocaleSwitcher.vue
A helper component who allow the user to change
the default local language (i18n).
---

<template>
  <div class="locale-switcher">
    <select v-model="$i18n.locale">
      <option
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        :value="lang"
      >
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'LocaleSwitcher',

  data() {
    return { langs: ['en', 'fr'] };
  },
};
</script>
