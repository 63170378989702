<template>
  <div>
    <v-btn
      dark
      x-large
      color="indigo"
      style="width:250px;"
      @click="callVillage"
    >
      <v-icon left>
        mdi-bell
      </v-icon>
      <span>Avertir l’équipe <br>du village</span>
    </v-btn>
  </div>
</template>

<script>
import gql from 'graphql-tag';

export default {

  data() {
    return {
      companyId: '604a39419a3024bc9a4eca29', // 603f8ba5bf132d2b8e40962b = Bag-Era | 604a39419a3024bc9a4eca29 = VillageByCA
      villageName: 'Village By CA',
      villageLogo: 'https://stone-edge-welcomebyca-assets-public.s3.eu-west-1.amazonaws.com/village.png',
      msg: 'Hi there!',
    };
  },

  methods: {
    callVillage() {
      this.sendMail();
      this.$router.push({
        name: 'Ring',
        params: { companyName: this.villageName, companyLogo: this.villageLogo },
        query: { rotate: this.$route.query.rotate },
      });
    },

    async sendMail() {
      // console.log('SEND MAIL TO: ', this.companyId, this.title);
      const mail = await this.$apollo.query({
        query: gql`
          query Notify($input: HomeCompanyUpdateInput) {
            NotifyCompany(input: $input) {
              companies {
                email
              }
            }
          }`,
        variables: {
          input: {
            companyId: this.companyId,
          },
        },
        fetchPolicy: 'network-only',
      });
      // console.log('MAIL SENT: ', mail);
      return mail;
    },
  },
};
</script>

<style scoped>
.super-class {
  background: #4b79b6;
}
</style>
